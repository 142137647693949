import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { TesteComponent } from './teste/teste.component';
import { BarcodeComponent } from './barcode/barcode.component';


@NgModule({
  declarations: [
    AppComponent,
    TesteComponent,
    BarcodeComponent
  ],
  imports: [
    BrowserModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
